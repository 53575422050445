<template>
  <SpritesheetAnimation
    class="table-seat-animation"
    :id="`sprite-${from}-${type}-${to}`"
    :spritesheet="
      require('@/assets/images/animations/expression/' + type + '.png')
    "
    :json="require('@/assets/images/animations/expression/' + type + '.json')"
    :yoyo="false"
    :fps="30"
    :required-height="160"
    :autoplay="false"
    :ref="`sprite-${from}-${type}-${to}`"
  />
</template>

<script>
import SpritesheetAnimation from "../../Utils/SpritesheetAnimation";
import { mapState, mapGetters } from "vuex";

export default {
  name: "GlobalAnimation",
  props: {
    type: {
      type: String,
      required: true,
    },
    from: {
      type: Number,
      required: true,
    },
    to: {
      type: Number,
      required: true,
    },
    sendPlayerId: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      expressionAudios: [
        "yumurta",
        "tokat",
        "silah",
        "opucuk",
        "kalp",
        "domates",
        "bomba",
        "bira",
        "aglama",
        "gul",
        "konfeti",
        "salatalik",
      ],
    };
  },
  components: { SpritesheetAnimation },
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      nGetSeats: "service/nGetSeats",
      getActiveRoomState: "service/getActiveRoomState",
      getAnimations: "gift/getAnimations",
      nAmISitting: "service/nAmISitting",
      nGetMySeat: "service/nGetMySeat",
    }),
    animationIsMe() {
      return this.sendPlayerId === this.user.id;
    },
    getComputedIndex() {
      if (!this.nAmISitting) return this.$props.index;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (this.$props.index + shiftValue) % 9;
    },
    data() {
      return this.nGetSeats[this.$props.index];
    },
  },
  methods: {
    animationCompleted() {
      if (
        this.$refs[`sprite-${this.from}-${this.type}-${this.to}`] &&
        this.$refs[
          `sprite-${this.from}-${this.type}-${this.to}`
        ].hasOwnProperty("playOnce")
      ) {
        this.$refs[`sprite-${this.from}-${this.type}-${this.to}`].playOnce(
          true
        );

        if (this.expressionAudios.includes(this.type)) {
          this.$plugins?.audio?.play(this.type);
        }
      }

      setTimeout(() => {
        this.$store.commit("gift/newDeleteAnimation", {
          from: this.sendPlayerId,
          id: this.type,
        });
      }, 1900);
    },

    getMethodIndex(number) {
      if (!this.nAmISitting) return number;

      let shiftValue = 7 - this.nGetMySeat.position;
      if (shiftValue < 0) shiftValue = 8;

      return (number + shiftValue) % 9;
    },
  },
  mounted() {
    const targetEl = document.getElementsByClassName(`expression-${this.to}`);
    const fromEl = document.getElementsByClassName(
      `expression-${this.data.id}`
    );

    if (!(targetEl && targetEl.length > 0) || !(fromEl && fromEl.length > 0)) {
      console.log("target or from el not found");
      this.$store.commit("gift/newDeleteAnimation", {
        from: this.sendPlayerId,
        id: this.type,
      });
      return;
    }

    let coorXDiff = 0;

    if (fromEl[0].offsetLeft > targetEl[0].offsetLeft) {
      coorXDiff = targetEl[0].offsetLeft - fromEl[0].offsetLeft;
    } else {
      coorXDiff = targetEl[0].offsetLeft - fromEl[0].offsetLeft;
    }

    let coorYDiff = 0;
    if (fromEl[0].offsetTop > targetEl[0].offsetTop) {
      coorYDiff = targetEl[0].offsetTop - fromEl[0].offsetTop;
    } else {
      coorYDiff = targetEl[0].offsetTop - fromEl[0].offsetTop;
    }

    this.$animejs({
      targets: `#sprite-${this.from}-${this.type}-${this.to}`,
      translateX: coorXDiff,
      translateY: coorYDiff,
      duration: 1000,
      easing: "linear",
      complete: this.animationCompleted,
    });
  },
};
</script>

<style lang="scss">
.table-seat-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  .vue-sprite {
    canvas {
      z-index: 100 !important;
    }
  }
}
</style>
